import React from 'react';

export interface RestTime {

  /**
   * -1代表当前时间小于开始时间
   * 1代表当前时间大于等于开始时间
   */
  type: -1 | 1;

  // 剩余秒,结合1进行其它逻辑
  restSeconds: number;
  hour: number;
  minute: number;
  seconds: number;
}

// 时间单位毫秒
export function useRestTimeHook(startDate: number, endDate: number): RestTime {
  function getRestSeconds(currentTime: number, endTime: number): number {
    const totalSeconds = Math.floor((endTime - currentTime) / 1000);
    return totalSeconds <= 0 ? 0 : totalSeconds;
  }

  function calculateTime(restTime: number): Pick<RestTime, 'hour' | 'minute' | 'seconds'> {
    return {
      hour: Math.floor(restTime / (60 * 60)),
      minute: Math.floor(restTime / 60) % 60,
      seconds: restTime % 60,
    };
  }

  const [
    currentDate,
    updateCurrentDate,
  ] = React.useState<number>(Date.now());

  const timer = React.useRef(null);

  React.useLayoutEffect(() => {
    const previousId = timer.current;
    if (currentDate <= startDate || currentDate <= endDate) {
      timer.current = setTimeout(() => updateCurrentDate(Date.now()), 1000);
    }

    return () => window.clearTimeout(previousId);
  }, [currentDate]);

  if (currentDate < startDate) {
    const restSeconds = getRestSeconds(currentDate, startDate);
    return {
      type: -1,
      restSeconds,
      ...calculateTime(restSeconds),
    };
  } else {
    const restSeconds = getRestSeconds(currentDate, endDate);
    return {
      type: 1,
      restSeconds,
      ...calculateTime(restSeconds),
    };
  }
}

