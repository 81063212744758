import { ClockCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { getStaticResourceUrl } from 'egenie-common';
import { TagList } from '../../homePage/dynamicInfo';
import styles from './activitiesCard.less';
import { useRestTimeHook } from './useRestTimeHook';

interface ActivitiesCardProps {

  // 活动id
  id?: number | string;

  // 活动标题
  name?: string;
  beginTime?: string;
  endTime?: string;
  discountEnable?: number;
  discount?: number;

  // 容器渐变颜色
  bgColor?: string;

  // 图片url
  goodsPicList?: string[];

  // 外层容器样式
  style?: React.CSSProperties;

  // 外层容器类名
  className?: string;
  tagList;
}

const TimeContainer: React.FC<{ beginTime: number; endTime: number; startColor: string; }> = ({
  beginTime,
  endTime,
  startColor,
}) => {
  const time = useRestTimeHook(beginTime, endTime);
  const hour = time.hour < 10 ? `0${time.hour}` : time.hour;
  const minute = time.minute < 10 ? `0${time.minute}` : time.minute;
  const seconds = time.seconds < 10 ? `0${time.seconds}` : time.seconds;

  if (time.type === 1 && time.restSeconds > 60 * 60 * 24) {
    return (
      <div
        className={styles.restTimeContainer}
        style={{ color: fontBaseColor }}
      >
        活动火热进行中…
      </div>
    );
  }

  return (
    <div
      className={styles.restTimeContainer}
      style={{ color: fontBaseColor }}
    >
      {time.type === -1 ? '距活动开始' : '距活动结束'}
      <span style={{ color: startColor }}>
        {hour}
      </span>
      时
      <span style={{ color: startColor }}>
        {minute}
      </span>
      分
      <span style={{ color: startColor }}>
        {seconds}
      </span>
      秒
    </div>
  );
};

let i = 0;
const fontBaseColor = '#fff';
export const ActivitiesCard: React.FC<ActivitiesCardProps> = ({
  className = '',
  style = {},
  bgColor = '#fb4307,#fcbaa5',
  id = '',
  beginTime = '',
  endTime = '',
  discount = 10,
  name = '',
  goodsPicList = [],
  discountEnable,
  tagList,
}) => {
  const startColor = bgColor.split(',')[0];
  const endColor = bgColor.split(',')[1];

  const beginTimeDate: moment.Moment = moment(beginTime);
  const endTimeDate: moment.Moment = moment(endTime);

  return (
    <div
      className={classnames(styles.cardContainer, className)}
      onClick={() => window?.top?.open(`/egenie-ts-vogue/searchResult/index?activeId=${id}&pageOrigin=active`)}
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${getStaticResourceUrl('pc/ts/egenie-ts-vogue/images/selectedActivitiesTexture.png')}), linear-gradient(to bottom,${startColor} 0%,  ${endColor} 100%)`,
        ...style,
      }}
    >
      <div
        className={styles.timeContainer}
        style={{ color: fontBaseColor }}
      >
        <ClockCircleOutlined/>
        &nbsp;
        <span>
          活动时间
          &nbsp;
          {beginTimeDate.month() + 1}
          月
          {beginTimeDate.date()}
          日-
          {endTimeDate.month() + 1}
          月
          {endTimeDate.date()}
          日
        </span>
      </div>
      <div
        className={styles.titleContainer}
        style={{ color: fontBaseColor }}
      >
        <span>
          {name}
        </span>
        &nbsp;
        <span style={{ backgroundColor: '#fff' }}>
          <i
            className="icon-home_xq"
            style={{ color: startColor }}
          />
        </span>
      </div>
      <TagList
        bgColors={[
          startColor,
          endColor,
        ]}
        tagList={tagList}
      />
      <TimeContainer
        beginTime={beginTimeDate.valueOf()}
        endTime={endTimeDate.valueOf()}
        startColor={startColor}
      />
      <div className={styles.imgContainer}>
        {
          goodsPicList.slice(0, 4)
            .map((item) => (
              <img
                alt=""
                key={i++}
                loading="lazy"
                src={item}
              />
            ))
        }
      </div>
    </div>
  );
};

