import { Col, Row, Spin } from 'antd';
import type { BaseData } from 'egenie-common';
import { request, getStaticResourceUrl } from 'egenie-common';
import React, { useEffect } from 'react';
import { setLocationHref } from '../../utils';
import { BlankSegment } from '../components';
import EmptyStatus from '../components/empty';
import { Navigation } from '../components/navigation';
import { ActivitiesCard } from './activitiesCard/activitieCard';
import styles from './index.less';
import type { ActivitiesItem } from './types';

const enumActivitiesTypes = {
  running: {
    value: '1',
    label: '正在进行中',
  },
  notStart: {
    value: '0',
    label: '预热中',
  },
};

export default function() {
  useEffect(() => {
    setLocationHref();
  }, []);

  const [
    activitiesTypes,
    setActivitiesTypes,
  ] = React.useState<string>(enumActivitiesTypes.running.value);
  const [
    activitiesData,
    setActivitiesData,
  ] = React.useState<ActivitiesItem[]>([]);

  const [
    loading,
    setLoading,
  ] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    setLoading(true);
    request<BaseData<ActivitiesItem[]>>({
      url: '/api/mall/rest/square/anon/promotions',
      params: {
        promotionStatus: activitiesTypes,
        num: 200,
      },
    })
      .then((info) => {
        setActivitiesData(info.data || []);
        setTimeout(() => {
          setLoading(false);
        });
      });
  }, [activitiesTypes]);

  return (
    <div className={styles.outerContainer}>
      <Navigation/>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <header>
            <img
              alt=""
              src={getStaticResourceUrl('pc/ts/egenie-ts-vogue/images/selectedActivitiesBg.png')}
            />
            <img
              alt=""
              src={getStaticResourceUrl('pc/ts/egenie-ts-vogue/images/selectedActivitiesTitle.png')}
            />
          </header>
          <section className={styles.contentContainer}>
            {
              Object.values(enumActivitiesTypes)
                .map((item) => (
                  <span
                    className={item.value === activitiesTypes ? styles.active : undefined}
                    key={item.value}
                    onClick={() => setActivitiesTypes(item.value)}
                  >
                    {item.label}
                  </span>
                ))
            }
          </section>
          <Spin spinning={loading}>
            {
              loading ? <BlankSegment/> : (
                <div>
                  {
                    activitiesData.length <= 0 ? <EmptyStatus/> : (
                      <Row>
                        {
                          activitiesData.map((item) => (
                            <Col
                              key={item.id}
                              span={12}
                            >
                              {!item.customEntryUrl ? (
                                <ActivitiesCard
                                  {...item}
                                  goodsPicList={(item.goodsPicList || []).map((item) => item.mainPicUrl)}
                                />
                              ) : (
                                <img
                                  alt=""
                                  className={styles.customEntryUrl}
                                  onClick={() => {
                                    window.open(`/egenie-ts-vogue/searchResult/index?activeId=${item.id}&pageOrigin=active`);
                                  }}
                                  src={item.customEntryUrl}
                                />
                              )}
                            </Col>
                          ))
                        }
                      </Row>
                    )
                  }
                </div>
              )
            }
          </Spin>
        </div>
      </div>
    </div>
  );
}
