/**
 * 用于空数据时展示，可配置提示信息
 * @param showTxt
 */
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from './index.less';

const EmptyStatus = (props): JSX.Element => {
  return (
    <div className={styles.emptyWrap}>
      <img
        src={props.url || `${PUBLIC_IMAGE_URL}emptyDataFront.png`}
        style={props.style || {}}
      />
      {' '}
      <div
        className={styles.emptyTxt}
        style={props.textStyle || {}}
      >
        {props.showTxt || '暂无内容'}
      </div>
    </div>
  );
};

export default EmptyStatus;
